/* eslint-disable no-console */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import {
  Datagrid,
  List,
  BooleanField,
  TextInput,
  FunctionField,
  downloadCSV,
  TextField,
} from "react-admin";
import Chip from "@mui/material/Chip";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import copy from "copy-to-clipboard";
import jsonExport from "jsonexport/dist";

const exporter = (registrations) => {
  const speakersExport = registrations.map((registration) => {
    const {
      firstName,
      lastName,
      title,
      email,
      phone,
      company,
      country,
      privacyPolicy,
      exhibitor,
      registrationsScans,
    } = registration;
    return {
      firstName,
      lastName,
      title,
      company,
      country,
      email,
      phone,
      dataConsent: privacyPolicy ? "YES" : "NO",
      scanned: exhibitor ? "YES" : "NO",
      attended:
        registrationsScans?.length > 0
          ? registrationsScans.map((r) => r.pass).join("|")
          : "NOT ATTENDED",
    };
  });
  jsonExport(
    speakersExport,
    {
      headers: [
        "firstName",
        "lastName",
        "title",
        "company",
        "country",
        "email",
        "phone",
        "dataConsent",
        "scanned",
        "attended",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "delegates");
    }
  );
};

const RegistrationFilters = [<TextInput label="Search" source="search" alwaysOn key={0} />];
export default (props) => (
  <List
    {...props}
    sort={{ field: "createdAt", order: "DESC" }}
    perPage={15}
    filters={RegistrationFilters}
    hasCreate={false}
    hasedit="false"
    exporter={exporter}
  >
    <Datagrid rowClick="" bulkActionButtons={false}>
      <FunctionField
        label="Name"
        source="firstName"
        render={(record) => <b>{`${record.firstName} ${record.lastName}`}</b>}
        key="name"
      />
      <TextField source="title" />
      <TextField source="company" />
      <TextField source="country" />
      <FunctionField
        label="Phone"
        source="phone"
        render={(record) => (
          <Tooltip title="Click to copy">
            <Link
              component="button"
              onClick={() => {
                copy(`+${record.phone}`);
              }}
              underline="none"
            >
              +{record.phone}
            </Link>
          </Tooltip>
        )}
        key="phone"
      />
      <FunctionField
        label="Email"
        source="email"
        render={(record) => (
          <Tooltip title="Click to copy">
            <Link
              component="button"
              onClick={() => {
                copy(record.email);
              }}
              underline="none"
            >
              {record.email}
            </Link>
          </Tooltip>
        )}
        key="email"
      />
      <BooleanField
        source="privacyPolicy"
        key="privacyPolicy"
        label="Data Consent"
        sortable={false}
        TrueIcon={() => <CheckCircleSharpIcon color="success" />}
        FalseIcon={() => <CancelSharpIcon color="warning" />}
      />
      {/* <BooleanField
        source="exhibitor"
        key="exhibitor"
        label="Scanned"
        looseValue
        sortable={false}
        TrueIcon={() => <CheckCircleSharpIcon color="success" />}
        FalseIcon={() => <CancelSharpIcon color="warning" />}
      /> */}
      <FunctionField
        label="Passes"
        source="passes"
        render={(record) => record.passes?.map((c) => <Chip label={c} key={c} />) || "-"}
        key="passes"
      />
      ,
      <FunctionField
        sortable={false}
        label="Attended"
        source="registrationsScans"
        render={(record) =>
          record.registrationsScans?.length > 0 ? (
            record.registrationsScans.map((r) => (
              <Chip label={r.pass} variant="outlined" key={r.pass} />
            ))
          ) : (
            <Chip label="NOT SCANNED" color="warning" />
          )
        }
        key="email"
      />
    </Datagrid>
  </List>
);
